// Override default variables before the import

// Very nice info on CSS:
// https://github.com/twbs/bootstrap-sass/blob/master/assets/stylesheets/bootstrap/_variables.scss
// https://github.com/twbs/bootstrap/blob/main/scss/_variables.scss
// http://colormind.io/bootstrap/

/*---------------------BootStrap & General Styling----------------------------------------- --------------------------------------------------------------*/

/////////////// BOOTSTAP COLORS ////////////////

$body-color: #353335; //this is the text color

//// Change Theme Colors:
$primary: #98a2a7;
$secondary: #887d78;
$success: #57aa6d;
$info: #396a8b;
$warning: #d49a35;
$danger: #f44336;
$light: #fbf7fa;
$dark: #946e67;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
);

$link-color: $info;
$link-hover-color: darken($link-color, 15%) !default;

$nav-link-color: $info !default;
$nav-link-hover-color: darken($nav-link-color, 15%) !default;

$component-active-color: #fbf7fa !default;
$component-active-bg: #709eb1 !default;

//////////////// TEXT STYPING ///////////////////

// stylelint-disable value-keyword-case
$font-family-sans-serif: system-ui, -apple-system, "Poppins", Roboto, "Segoe UI",
  "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  "Liberation Mono", "Courier New", monospace;
// stylelint-enable value-keyword-case
$font-family-base: $font-family-sans-serif !default;

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-root: null;
$font-size-base: 0.95rem; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * 0.875;
$font-size-lg: $font-size-base * 1.25;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

$font-weight-base: $font-weight-normal;

$line-height-base: 1.5;
$line-height-sm: 1.25;
$line-height-lg: 2;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

#button-round {
  border-radius: 20px;
}

/*---------------------Grid Breakpoints-------------------------------------------------------------------------------*/

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1700px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1615px,
);

/*---------------------Main Container----------------------------------------------------------------*/
.container-style {
  width: 80vw;
  margin: 0 auto;
  max-width: 1400px;
}

/*---------------------Login Button-------- --------------------------------------------------------------*/

#button-logout {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-right-style: none;
}

#button-edit {
  border-radius: 0px;
  border-left-style: none;
  border-right-style: none;
}

#button-add {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left-style: none;
}

/*---------------------Serach & Add Bar----------------------------------------- --------------------------------------------------------------*/

#search-box-icon {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  //border-color: #396a8b !important;
  //border-right-style: none;
}

#search-box-input-fied {
  width: 25vw;
  max-width: 400px;
  //border-left-style: none;
  //border-right-style: none;
  //border-color: #396a8b !important;
}

#location-button-icon {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  //border-left-style: none;
  //border-color: #396a8b !important;
  cursor: pointer;
}

#search-dropdown {
  margin-left: 40px;
}

.search-result-dropdown-hidden {
  position: fixed;
  display: none;
}

.search-result-dropdown {
  position: fixed; /* Sit on top of the page content */
  margin-left: 40px;
  margin-top: 2px;
  padding: 10px;
  background-color: white;
  box-shadow: 5px 5px 6px #e0e0e0, -5px 6px #ffffff;
  z-index: 500;
  border: 0.2px solid #709eb1;
}

.search-list-headline {
  font-size: 12px;
  color: #887d78;
}

.search-list-item {
  cursor: pointer;
  margin: 2px;
  font-size: 14px;
  color: $info;
}

/// Color of the Input Fild
.form-control:focus {
  border-color: #709eb1 !important;
}
$input-focus-box-shadow: none;

/// Remove the Dropdown Arroow from the ADD Field
.dropdown-toggle::after {
  display: none !important;
}

.icon-tab {
  margin-bottom: 3px;
  margin-right: 5px;
  font-size: 16px;
}

///// Mandatory Field Icon & Text Color
.mandatory-field.empty {
  color: $warning;
  //margin-bottom: 7px;
}

.mandatory-field {
  color: $body-color;
  //margin-bottom: 7px;
}

/*---------------------NavBar-------- --------------------------------------------------------------*/

#nav-container {
  margin-bottom: 25px;
  padding: 0px 25px 20px 25px;
}

////// Sytling of Icons//////

.navicon-diabled {
  width: 35px;
  margin-bottom: 5px;
  padding: 7px;
}

.navicon-png {
  width: 35px;
  margin-bottom: 5px;
  padding: 7px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 5px 5px 6px #e0e0e0, -5px -5px 6px #ffffff;
}

.navicon-png.active {
  width: 35px;
  margin-bottom: 5px;
  padding: 7px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: inset 5px 5px 6px #e0e0e0, inset -5px -5px 6px #ffffff;
}

.select-item {
  cursor: pointer;
}

////// Sytling of Text
.nav-text {
  color: #709eb1;
}

.nav-text.active {
  color: $info;
  font-weight: 700;
}

/*---------------------Leaflet Map----------------------------------------- --------------------------------------------------------------*/

.leaflet-container {
  width: 100%;
  height: 60vh;
  border-radius: 20px;
}

/*----------------------Popup -----------------------*/

//// Outer content
.leaflet-popup-content-wrapper {
  border-radius: 5px;
  /// background: red;
}

/// Inter content
.leaflet-popup-content-wrapper .leaflet-popup-content {
  // background: green;
}

//// Color of the Arrow
.leaflet-popup-tip-container {
}

.popup-header {
  font-weight: 700;
  margin-bottom: 10px;
}

.popup-body {
  //font-weight: 700;
}

.popupDetails {
  font-size: 11px;
}

/*---------------------List Pages-------------------------- --------------------------------------------------------------*/

.center-text {
  text-align: center;
}

.right-text {
  text-align: right;
}

.list-icon {
  font-size: 20px;
  cursor: pointer;
  margin: 0 5px 0 10px;
}

.list-icon.show {
  color: $info;
}

.list-icon.hide {
  color: $secondary;
}

.list-icon.edit {
  color: $warning;
}

.list-icon.delete {
  color: $danger;
}

/// Small Button for Edit and Delete in Lists
#small-btn {
  width: 25px;
  height: 25px;
  //margin-left: 15;
  //padding-left: 8px;
  //padding-top: 3px;
  //border-radius: 8px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.15);
}

/*--------------------Media Changes- --------------------------------------------------------------*/

@media (max-width: 1199px) {
  .button-text {
    display: none !important;
  }

  .container-style {
    width: 90vw;
    margin: 0 auto;
  }

  .list-table {
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  .nav-text {
    //display: none !important;
    font-size: 13px;
  }

  .navicon {
    font-size: 20px;
  }

  #search-box-input-fied {
    width: 25vw;
    max-width: 100vw;
  }
}

@media (max-width: 767px) {
  #nav-container {
    margin-bottom: 10px;
    padding: 0px 15px 10px 15px;
  }

  .nav-text {
    display: none !important;
  }

  .login-button {
    display: none !important;
  }

  .navicon-png {
    margin-bottom: 15px;
  }

  .navicon-png.active {
    margin-bottom: 15px;
  }
}

@media (max-width: 575px) {
  #margin-uneven {
    margin-left: 0px;
  }
  #nav-container {
    margin-bottom: 10px;
    padding: 0px 15px 0px 15px;
  }
}

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
